@import "../../assets/styles/variables";

.products {
  position: relative;

  .floatingText {
    position: sticky;
    left: 0;
    top: 0;
    height: 100%;
    width: 60px;

    .text {
      padding: 2.5rem 0.5rem;
      background-color: $primary;
      font-weight: bold;
      writing-mode: vertical-rl;
      border-bottom-right-radius: 1.25rem;
      font-size: 1.75rem;
    }

    @include breakpoint(untilTablet) {
      position: static;
      width: max-content;

      .text {
        writing-mode: horizontal-tb;
        padding: 1rem 2rem;
        font-size: 1rem;
      }
    }
  }

  .productsWrapper,
  .skeletonWrapper {
    width: 90%;
    margin: auto;

    @include breakpoint(untilTablet) {
      width: 100%;
      margin-top: 1rem;
    }
  }

  .titlePage {
    text-align: center;
    font-size: 2rem;
    font-family: "Crimson Pro", serif;
    margin-bottom: 2rem;
    border-radius: 1.25rem 1.25rem 0 0;

    @include breakpoint(untilTablet) {
      font-size: 1.25rem;
    }
  }

  .btn-primary {
    color: white;
  }

  .btn-secondary {
    color: gray;
  }

  .productsOption {
    .sort {
      width: 250px;
      display: flex;
      align-items: center;

      .text {
        white-space: nowrap;
        margin-right: 1rem;
      }
    }
  }

  .sort {
    .form-select {
      border-radius: 0;
      border: none;
      border-bottom: 1px solid lightgray;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .productItems {
    .item {
      margin-bottom: 2rem;
      padding: 1rem;

      .image {
        margin: -1rem;
        margin-bottom: 0;

        img {
          width: 100%;
          height: 220px;
          object-fit: cover;
        }
      }

      .info {
        margin-top: -2rem;
        background-color: white;
        z-index: 2;
        position: relative;
        padding: 1rem;
        border: 1px solid #cacaca;
        min-height: 180px;

        @include breakpoint(untilTablet) {
          min-height: 0;
        }
      }

      .title,
      .address,
      .price,
      .desc {
        margin-bottom: 0.5rem;
        text-align: center;
      }

      .desc {

        div,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        span,
        p {
          font-size: 14px !important;
          color: #212529 !important;
          line-height: 1;
          font-weight: 300;
        }
      }

      .price {
        color: $primary;
        font-weight: bold;
      }

      .address {
        color: rgb(105, 105, 105);
        font-family: "Crimson Pro", serif;
        font-style: italic;
      }

      .title,
      .desc {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .title {
        -webkit-line-clamp: 1;
        font-size: 1.25rem;
      }

      .desc {

        div,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          font-size: 14px !important;
        }
      }

      .buttonWrapper {
        margin-top: 0;
        padding-top: 0.5rem;
        display: flex;
        justify-content: center;

        .btnView {
          padding: 0.5rem 2rem;
        }
      }
    }
  }
}